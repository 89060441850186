.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 97%;
    background-color: transparent;
    border: 1px solid rgb(223, 223, 223);
    margin: 0 !important;
    border-radius: 0 !important;
}


.write {
    display: flex;
    flex-direction: row;
    bottom: 0;
    width:97%;
    margin-bottom: 30px;
}


textarea {
    width: 80%;
    height: 60px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    border: 1px solid rgb(223, 223, 223);
    font-family: Georgia, 'Times New Roman', Times, serif;
    background-color: white;
    resize: none;
}

textarea:focus {
    outline-width: 1px;
    outline-color: rgb(77, 202, 255);
}

.btn {
    width: 20%;
    border-radius: 5px;
    margin-left: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    border: 1px solid rgb(223, 223, 223);
    background-color: rgb(77, 202, 255);
}


.btn:hover {
    background-color: rgb(47, 155, 201);
    color: white;
    cursor: pointer;
}

.cont {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 85%;
    margin-top: 10px;
    margin-bottom: 12px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    overflow-y: scroll;
    background-color: white;
}

.message-cont {
    width: 500px;
    margin-block: 10px;
    margin-left: 15px;
    margin-right: 10px;
}


.msg-name-and-date {
    display: flex;
    color: rgb(168, 168, 168);
}

.msg-body {
    padding: 10px;
    border-radius: 3px;
    word-wrap: break-word;
    box-shadow: 0px 8px 16px -8px rgba(206, 206, 206, 0.779);
}

.my-msgs {
    align-self: flex-start;
}

.other-msgs {
    align-self: flex-end;
}


.msg__est {
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 8px 16px -8px rgba(206, 206, 206, 0.779);
    background-color: rgb(255, 244, 197);
    width: 500px;
    justify-self: center;
    align-self: center;
    margin-bottom: 50px;
}


.main__h5_cont {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-left: 25px;
}


.main__h5 {
    font-weight: 100;
    width: 100%;
    margin-left: 8px;
}

.main__circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(132, 249, 132);
}