.subSet__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.subSet__service_cont {
    display: flex;
    width: 1000px;
    margin-left: 15px;
    padding-block: 5px;
    padding-inline: 5px;
    margin-bottom: 15px;
}

.subSet__service_cont p {
    text-transform: capitalize;
    width: 300px;
    margin-right: 10px;
}

.subSet__service_cont button {
    cursor: pointer;
}