.sidebar__cont {
    width: 15%;
    height: 100%;
    background-color: rgb(1, 1, 41);
}

.sidebar__options {
    display: flex;
    color: white;
    width: 100%;
    height: 30px;
    align-items: center;
    padding-block: 10px;
    cursor: pointer;
}

.sidebar__options:hover {
    background-color: rgb(4, 4, 83);
}

.sidebar__options p {
    margin-left: 10px;
}

.sidebar__icons {
    margin-left: 10px;
}