.displayAllMessages__cont {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.displayAllMessages__inner_cont {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background-color: rgba(197, 224, 255, 0.516);
    overflow-y: scroll;
    overflow-x: hidden;
}

.displayAllMessages__h2 {
    font-weight: 100;
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 20px;
    align-self: flex-start;
    text-transform: capitalize;
}

.displayAllMessages__message_cont {
    margin: 5px;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 8px 16px -8px rgb(203, 203, 203);
    width: 500px;
    word-wrap: break-word;
}

.displayAllMessages__name {
    text-transform: capitalize;
    font-weight: 100;
    color: rgb(0, 0, 0);
}

.displayAllMessages__msg {
    padding-top: 10px;
    font-weight: 100;
    color: gray;
}

.displayAllMessages__cl {
    background-color: rgba(56, 171, 248, 0.3);
    align-self: flex-start;
    margin-left: 15px;
}

.displayAllMessages__spec {
    background-color: rgb(243, 243, 243);
    align-self: flex-end;
    margin-right: 15px;
}