.updateUser__cont {
    width: 900px;
}

.updateUser__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.updateUser__select {
    width: 200px;
    height: 20px;
    margin-left: 25px;
}

.updateUser__opt {
    padding-inline: 5px;
    padding-block: 3px;
    text-transform: capitalize;
}

.updateUser__fields_cont {
    margin-left: 20px;
    margin-top: 50px;
    width: 500px;
}

.updateUser__fields_cont div {
    width: 100%;
}

.updateUser__fields_cont span {
    display: block;
    width: 150px; 
}

.updateUser__fields_cont input {
    border-radius: 2px;
    border: 1px solid rgb(214, 214, 214);
    outline: none;
}

.updateUser__btn {
    margin-top: 50px;
    padding-inline: 10px;
    padding-block: 2px;
    cursor: pointer;
    margin-left: 15px;
}








.check-circle_2 {
    width: 6.5rem;
    height: 6.5rem;
    background-color: #00CAD9;
    border-radius: 50%;
    display: block;
    margin-bottom: 2rem;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
    position: relative;
    animation: circleScale_2 0.5s ease 1;
    animation-timing-function: cubic-bezier(.42, .97, .52, 1.49);
    margin: 0 auto;
    margin-top: 30px;
  }
  
  .check-circle__mark_2 {
    display: block;
  }
  
  .check-circle__mark_2:after {
    position: absolute;
    top: 3.25rem;
    left: 1.45rem;
    transform: translate(0, 0);
    content: '';
    opacity: 0;
    height: 3.25rem;
    width: 1.75rem;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    transform-origin: left top;
    animation-delay: 0.6s;
    animation-duration: 900ms;
    animation-name: checkmark_2;
    transform: scaleX(-1) rotate(135deg);
    animation-timing-function: cubic-bezier(.42, .97, .52, 1.49);
    animation-fill-mode: forwards;
  }
  
  @keyframes circleScale_2 {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes checkmark_2 {
    0% {
      height: 0;
      width: 0;
      opacity: 0;
    }
    20% {
      height: 0;
      width: 1.75rem;
      opacity: 1;
    }
    40% {
      height: 3.25rem;
      width: 1.75rem;
      opacity: 1;
    }
    100% {
      height: 3.25rem;
      width: 1.75rem;
      opacity: 1;
    }
  }