.accordion-container {
  width: 1200px;
  margin-left: 15px;
}

.accordion-section {
  border: 1px solid #eaeaea;
  margin-bottom: 5px;
  overflow: hidden;
}

.accordion-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s ease;
}

.accordion-header.open {
  background-color: #eaeaea;
}

.accordion-header.active {
  background: linear-gradient(90deg, #3498db2b, #297fb94b, #3498db3c); /* Gradient with dark elegant blue tones */
  background-size: 200% 100%;
  animation: gradientMovement 8s ease infinite;
}

@keyframes gradientMovement {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}


.cdl__cont_0 {
  background-color: white;
}

.accordion-content {
  padding: 10px;
  background-color: #fff;
  transition: max-height 0.3s ease; /* Adjust the transition property */
  max-height: 0; /* Set initial max-height to 0 for the transition effect */
  overflow: hidden;
}

.accordion-header.open + .accordion-content {
  max-height: 1000px; /* Adjust the maximum height */
  transition: max-height 0.3s ease; /* Adjust the transition property */
}

.cdl__cont {
  margin: 0px;
  margin-left: 15px;
}


.cdl__name {
  text-transform: capitalize;
  color: rgb(62, 62, 62);
  width: 170px;
  text-transform: capitalize;
}

.cdl__name_ {
  text-transform: capitalize;
  color: rgb(62, 62, 62);
  width: 170px;
}

.cdl__startDate {
  text-transform: capitalize;
  color: rgb(62, 62, 62);
  width: 150px;
  background-color: rgba(30, 143, 255, 0.14);
  padding-left: 10px;
  padding-top: 5px;
}

.cdl__limit {
  margin-left: 70px;
  text-align: center;
  width: 110px;
  text-align: left;
  background-color: rgba(128, 128, 128, 0.126);
  padding-left: 10px;
  padding-top: 5px;
}

.cdl__rem {
  margin-left: 60px;
  width: 70px;
  width: 75px;
}

.cdl__nxt_p {
  width: 110px;
  margin-left: 50px;
  padding-left: 10px;
  padding-top: 5px;
  background-color: rgba(6, 189, 6, 0.126);
}

.cdl__status {
  margin-left: 60px;
  width: 70px;
  text-transform: capitalize;
}

.cdl__status_ {
  margin-left: 60px;
  width: 70px;
  text-transform: capitalize;
  color: rgb(171, 226, 88);
}

.cdl__status_inactive {
  margin-left: 60px;
  width: 70px;
  text-transform: capitalize;
  background-color: rgba(207, 154, 154, 0.17);
  border-radius: 2px;
  color: rgb(180, 180, 180);
  height: fit-content;
}

.cdl__btn0 {
  margin-left: 2px;
}

.cdl__input {
  border: 1px solid rgb(213, 213, 213);
  width: 90px;
  border-radius: 2px;
  margin: 0px;
}

.cdl__btn {
  padding: 5px;
}

.cdl__update_title {
  text-transform: capitalize;
  color: rgb(62, 62, 62);
  width: 200px;
  padding-left: 10px;
  padding-top: 5px;
  background-color: rgba(255, 208, 0, 0.125);
}

.cdl__update_cont {
  display: flex;
  width: 200px;
  justify-content: left;
  align-items: flex-start;
  background-color: rgba(255, 208, 0, 0.125);
  padding-left: 10px;
  padding-top: 5px;
}

.cdl__green {
  color: rgb(116, 182, 18);
}

.cdl__yellow {
  color: rgb(217, 185, 27);
}

.cdl__red {
  color: rgb(237, 60, 60);
}