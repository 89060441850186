.unauth_cont {
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unauth_h2 {
    font-weight: 100;
    margin-bottom: 25px;
    margin-top: 100px;
    font-size: 50px;
}

.unauth_icon {
    object-fit: contain;
    font-size: 15rem !important;
    color: rgb(255, 200, 0);
}