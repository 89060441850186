.progressCard__box {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 200px;
    border-radius: 4px;
    /* background-image: linear-gradient(217deg, rgba(255, 4, 79, 0.6), rgb(255, 4, 79, 0) 70.71%), 
                linear-gradient(127deg, rgba(255, 70, 141, 0.6), rgb(255, 70, 141, 0) 70.71%), 
                linear-gradient(325deg, rgba(255, 0, 0, 0.6), rgb(255, 0, 0, 0.1) 70.71%); */
                

    background-image: linear-gradient(217deg, rgba(70, 130, 180, 0.6), rgba(28, 99, 213, 0) 70.71%),
    linear-gradient(127deg, rgba(70, 130, 180, 0.6), rgba(70, 150, 255, 0) 70.71%),
    linear-gradient(325deg, rgba(70, 130, 180, 0.6), rgba(0, 26, 255, 0.1) 70.71%);
    padding: 5px;
    box-shadow: 0 8px 16px -8px rgb(145, 145, 145);
    position: relative;
}

.progressCard__box::before {
    background: radial-gradient(1000px circle at var(--mouse-x) var(--mouse-y), rgba(4, 184, 255, 0.418), transparent 40%);
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2;
    transition: width 0.3s ease, height 0.3s ease;
    opacity: 0;
    transition: opacity 500ms;
    pointer-events: none;
}

.progressCard__box:hover::before {
    opacity: 1;
}

/* .progressCard__box:hover {
    background-image: linear-gradient(30deg, rgba(255, 4, 79, 0.9), rgb(255, 4, 79, 0) 70.71%),
    linear-gradient(260deg, rgba(255, 70, 141, 0.9), rgb(255, 70, 141, 0) 70.71%), 
    linear-gradient(310deg, rgba(255, 0, 0, 0.9), rgb(255, 0, 0, 0.1) 70.71%);
    -webkit-transition: background-image 1000ms linear;
    -moz-transition: background-image 1000ms linear;
    -o-transition: background-image 1000ms linear;
    -ms-transition: background-image 1000ms linear;
    transition: background-image 1000ms linear;
} */

#progressCard__serviceName {
    font-weight: 200;
    padding-top: 20px;
    margin: 10px;
    color: black;
    text-transform: capitalize;
    z-index: 5;
}

#progressCard__para {
    text-decoration: none;
    text-transform: capitalize;
    color: black;
    margin-left: 10px;
    font-size: medium;
    font-weight: 100;
    z-index: 4;
}