.report_bug_cont {
    display: flex;
    flex-direction: column;
    width: 1000px;
}

.report_bug_h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.report_bug_txt_area {
    width: 500px;
    height: 200px;
    resize: vertical;
    margin-left: 15px;
    max-height: 300px;
    box-sizing: border-box;
}


.report_bug_btn {
    margin-left: 15px;
    width: 100px;
    padding-block: 7px;
    padding-inline: 4px;
    margin-top: 25px;
    cursor: pointer;
}

.report_bug_submitted {
    margin-left: 15px;
    color: dodgerblue;
}