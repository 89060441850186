.reports__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
    text-transform: capitalize;
}

.reports__cont {
    width: 900px;
    height: 15px;
    margin-left: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    border-bottom: 1px solid rgb(182, 182, 182);
    gap: 20px;
    transition: background-image 0.5s ease;
    margin-bottom: 20px;
    position: relative;
}

.reports__cont::before {
    content: '';
    border-radius: inherit;
    background: radial-gradient(3500px circle at var(--mouse-x) var(--mouse-y), #4683b478, transparent 40%);
    width: 100%;
    height: 100%;
    transition: width 0.3s ease, height 0.3s ease;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    transition: opacity 500ms;
}

.reports__cont:hover::before {
    opacity: 1;
}

/* .reports__cont:hover {
    background-image: linear-gradient(145deg, rgb(156, 220, 255), rgba(235, 235, 235, 0));
    
} */

.reports__name {
    text-transform: capitalize;
    z-index: 4;
}

.reports__services {
    display: flex;
    margin-top: auto;
    /* color: rgb(255, 29, 67); */
    color: blue;
    margin-left: 15px;
    z-index: 4;
    text-transform: capitalize;
}

.reportsName__cont {
    margin-left: 15px;
    padding: 5px;
    border-radius: 2px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(197, 197, 197);
}

.reportsName__cont:hover {
    /* background-image: linear-gradient(145deg, rgb(156, 220, 255), rgba(235, 235, 235, 0)); */
    color: blue;
}

.reportsName__fullRepName {
    text-transform: capitalize;
    display: flex;
}

.reportsName__para {
    text-transform: capitalize;
}

.reports__displayReports_cont {
    display: flex;
    margin-left: 15px;
    column-gap: 300px;
}

.reportsName__byName {
    text-transform: capitalize;
    margin-left: auto;
    color: rgb(199, 199, 199);
}

.reports__leftSide {
    margin-left: 5px;
}

.reports__uploadBtn {
    padding: 4px;
    padding-inline: 10px;
    cursor: pointer;
}

.reports__ls_par {
    font-weight: 100;
    margin-bottom: 20px;
}


.reports__box {
    width: 600px;
    margin-left: 15px;
    box-shadow: 0px 8px 16px -8px rgb(213, 213, 213);
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.reports__link {
    text-decoration: none;
    color: black;
}

.reports__link:hover {
    color: blue;
}


.reports__sent {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgb(136, 213, 19);
    font-size: larger;
    font-weight: 100;
    margin-top: 30px;
    animation: fadeIn 2s ease;
    animation-iteration-count: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.breadcrumbs {
    margin: 20px;
    text-decoration: none;
    color: blue;
}


.breadcrumbs:hover {
    color: rgb(0, 0, 133);
}