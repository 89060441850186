.communications-cont {
    display: flex;
    width: 1200px;
    flex-direction: column;
    justify-content: flex-start;
}

.comm-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    /* background: linear-gradient(90deg, rgb(219, 219, 219), rgb(243, 243, 243)); */
    width: 900px;
    margin-top: 15px;
    border-radius: 0px;
    text-decoration: none !important;
    color: black;
    cursor: pointer;
    position: relative;
}

.comm-box::before {
    background: radial-gradient(3500px circle at var(--mouse-x) var(--mouse-y), #4683b478, transparent 40%);
    content: '';
    border-radius: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: width 0.3s ease, height 0.3s ease;
    transition: opacity 500ms;
}

.comm-box:hover::before {
    opacity: 1;
}

/* .comm-box:hover {
    background: linear-gradient(90deg,rgb(171, 205, 255), rgb(243, 243, 243));
} */


.dv {
    color: rgb(234, 234, 234);
    margin-left: 10px;
    border-top: 1px solid rgba(211, 211, 211, 0.801);
}


.comm-box h4 {
    font-weight: 100;
    text-transform: capitalize;
}