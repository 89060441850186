.dbr_cont {
    display: flex;
    flex-direction: column;
    width: 1000px;
}

.dbr_h2 {
    font-weight: 100;
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 65px;
    display: flex;
    align-items: center;
}

.dbr_reports_box {
    display: flex;
    margin-left: 15px;
    width: 100%;
    margin-bottom: 15px;
}

.dbr_report_p {
    color: rgb(154, 154, 154);
    text-transform: capitalize;
    width: 110px;
}


.dbr_report {
    color: palevioletred;
    width: 600px;
}

.dbr_report_status {
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 4px;
    padding: 2px;
}

.dbr_report_status:hover {
    background-color: rgb(172, 255, 172);
}

.dbr_report_p_date {
    color: rgb(154, 154, 154);
    text-transform: capitalize;
    width: 170px;
}