/* body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: blue;
    color: black;
} */

.loading {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}

.ring {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: ring 2s linear infinite;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
        box-shadow: 1px 5px 2px #63eaff;
    }

    50% {
        transform: rotate(180deg);
        box-shadow: 1px 5px 2px #1db7d5;
    }

    80% {
        transform: rotate(288deg);
        box-shadow: 1px 5px 2px #0456c8;
    }

    100% {
        transform: rotate(360deg);
        box-shadow: 1px 5px 2px #042b86;
    }
}


.ring:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.loading-text {
    color: black;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
}

@keyframes text {
    50% {
        color: rgb(243, 243, 243);
    }
}