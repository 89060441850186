.allCommunications__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.allCommunications__room_c {
    margin-left: 15px;
    margin-bottom: 20px;
    padding: 5px;
    color: gray;
}

.allCommunications__room_c:hover {
    color: rgba(128, 128, 128, 0.66);
}


.allCommunications__room_names {
    margin-left: 15px;
    text-transform: capitalize;
    margin-bottom: 20px;
    color: dodgerblue;
}

.allCommunications__room_names:hover {
    color: rgb(87, 177, 230);
}

.allCommunications__title_div {
    display: flex;
    width: 700px;
}

.allCommunications__title_div p {
    margin-left: auto;
    margin-top: 25px;
    cursor: pointer;
}

.allCommunications__title_div p:hover {
    color: rgb(9, 161, 208);
}