.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    /* background: linear-gradient(45deg, rgba(155, 248, 255,1), rgba(143, 212, 255, 0.892), rgb(2, 137, 255), rgb(63, 217, 255)); */
    /* background-color: rgb(241, 241, 241); */
    /* background-size: 400% 400%; */
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    animation: gradient 20s ease infinite;
    animation: bgPatternMotion 110s ease-in-out infinite;
}

/* @keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
} */


/* @keyframes bgPatternMotion {
    0% {
        background-position: 100% 0%;
    }


    100% {
        background-position: 0% 100%;
    }
} */

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 10px;
    border: 1px solid rgba(44, 44, 44, 0.211);
    border-radius: 5px;
    margin-bottom: 60px;
    /* box-shadow: 0px 0px 5px rgb(99, 99, 99); */
    backdrop-filter: blur(10px);
    background-color: transparent;
    filter: blur(30%);
    background-repeat: no-repeat;
    background-size: contain;
}

.form {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

input {
    margin-block: 10px;
    width: 250px;
    height: 25px;
    border-radius: 2px;
    border: 0px;
    padding-left: 5px;
}


input:focus {
    outline-width: 1px;
    outline-color: rgb(0, 142, 203);
}


.login-button {
    height: 30px;
    width: 257px;
    margin-block: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    cursor: pointer;
    background-color: dodgerblue;
    border: none;
    border-radius: 2px;
}

.login-button:hover {
    background-color: rgb(14, 115, 215);
}

label {
    margin-top: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}


.login-header {
    display: flex;
}

#login-logo {
    width: 350px;
    margin-bottom: 30px;
    margin-top: 50px;
}

.footer {
    display: flex;
    width: 95vw;
    position: fixed;
    bottom: 0;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 15px;
}

.footer h5 {
    font-weight: 100;
}

#name {
    margin-left: auto;
}

h6 {
    margin-inline: 20px;
}

.form input {
    border: 1px solid rgb(185, 185, 185);
}

.form label {
    color: white;
}

#login-video-bg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.login_not_desk {
    color: white;
    font-size: 25px;
    font-weight: 100;
    text-align: center;
    width: 250px;
}