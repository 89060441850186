.addUser__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.addUser__input_cont {
    margin-left: 25px;
}

.addUser__input_cont span {
    margin-right: 20px;
}

.addUser__input_cont input {
    border-radius: 2px;
}

.addUser__input_cont input:focus {
    outline-width: 1px;
    outline-color: rgb(192, 192, 192);
}

.addUser__role_option {
    padding: 10px;
    border-radius: 2px;
    width: 200px;
    height: 20px;
    cursor: pointer;
    margin-inline: 20px;
}

.addUser__role_option:hover {
    background-color: rgb(208, 208, 208);
}

.addUser__client {
    background-color: rgba(200, 149, 101, 0.716)
}

.addUser__specialist {
    background-color: rgb(133, 216, 133);
}

.addUser__assistant {
    background-color: rgb(253, 194, 85);
}

.addUser__btn {
    width: fit-content;
    margin-left: 30px;
    padding: 10px;
    background-color: rgb(109, 202, 255);
    border: none;
    border-radius: 2px;
    padding-inline: 20px;
    margin-top: 30px;
    cursor: pointer;
    margin-bottom: 50px;
}

.addUser__btn:hover {
    background-color: rgb(40, 176, 254);
}


.checkmark {
    width: 200px;
    margin: 0 auto;
    padding-top: 40px;
  }

  .checkmark svg {
    width: 200px;
    height: 100px;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 2s ease-in-out;
    -webkit-animation: dash 2s ease-in-out;
  }
  
  .spin {
    animation: spin 2s;
    -webkit-animation: spin 2s;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
  }
  
  .checkmark__p {
    font-family: sans-serif;
    color:#69deb7;
    font-size: 30px;
    font-weight: 100;
    margin: 20px auto;
    text-align: center;
    animation: text .5s linear .4s;
    -webkit-animation: text .4s linear .3s;
  }
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

.addUser__refresh {
    margin-left: auto;
    margin-bottom: 65px;
    cursor: pointer;
  }

.addUser__refresh:hover {
    color: #1289cd;
}

.addUser__arch_cont {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 5px;
  align-items: center;
  margin-left: 70px;
}

.addUser__arch_cont div {
  display: flex;
  align-items: center;
}

.addUser__arch_cont div span {
  width: 100px;
}

.addUser__sub {
  color: gray;
}


.addUser_create_user_cont {
  display: flex;
  width: 100%;
  margin-left: 25px;
  align-items: center;
}

.addUser_create_user_cont div {
  display: flex;
  flex-direction: column;
  margin-right: 55px;
}

.addUser_create_user_cont button {
  width: 110px;
  height: 25px;
  margin-top: 35px;
  cursor: pointer;
}