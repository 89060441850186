header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 7%;
    background-color: rgb(1, 1, 41);
    padding-block: 10px;
}

header p {
    color: white;
    text-transform: capitalize;
}

#logo {
    object-fit: contain;
    width: 210px;
    margin-left: 10px;
    margin-right: 5px;
}

.logout-cont {
    margin-left: auto;
    margin-right: 20px;
}


.logout {
    border: none;
    margin-left: auto;
    padding: 5px;
    background-color: rgb(206, 206, 206);
    border-radius: 4px;
    cursor: pointer;
    font-family: Georgia, 'Times New Roman', Times, serif;
}


.logout:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
}