.progress__cont h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

#serviceProgress__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}


.serviceProgress_h4 {
    text-decoration: none;
    color: black;
    margin-left: 20px;
    padding: 5px;
    margin-block: 10px;
    border-radius: 3px;
    font-weight: 100;
    text-transform: capitalize;
    background-color: rgb(233, 233, 233);
}

.serviceProgress_h4:hover {
    color: blue;
}

.specProgress__p {
    margin-top: 20px;
    margin-left: 20px;
}

.specProgress__right_div {
    background-color: rgba(175, 175, 175, 0.225);
    width: 400px;
    margin-left: auto;
}

.serviceProgress__progressUpdate_div {
    display: flex;
    flex-direction: column;
    padding-inline: 10px;
    align-items: center;
}

#serviceProgress__progressUpdate {
    width: 100%;
    border-radius: 3px;
    margin-block: 10px;
    resize: vertical;
    max-height: 300px;
}

#serviceProgress__btn {
    align-self: flex-start;
    padding-block: 5px;
    padding-inline: 10px;
    cursor: pointer;
}

.progress__box {
    display: flex;
    align-items: center;
    margin-block: 28px;
}

.progress__box p {
    margin-left: 10px;
}


.circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 11, 84, 0.8);
    margin-left: 25px;
}


.pulse {
    animation: pulse 2s infinite;
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}



.breadcrumbs {
    display: block;
    margin: 20px;
    text-decoration: none;
    color: blue;
}


.breadcrumbs:hover {
    color: rgb(0, 0, 133);
}