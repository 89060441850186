* {
  font-family: serif !important;
  margin: 0px;
  padding: 0px;
}

body {
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: rgb(243, 243, 243);
}

.App {
  width: 100vw;
  height: 100vh;
}