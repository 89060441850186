.arch__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.arch__cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 1230px;
    margin-bottom: 20px;
    padding-block: 5px;
}

.arch__cont_ {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 1230px;
    margin-bottom: 20px;
    padding-block: 5px;
    margin-left: 65px;
}

.arch__cm {
    object-fit: contain;
    width: 20px;
    margin-bottom: 10px;
    padding-right: 190px;
}

.arch__p {
    width: 210px;
    text-transform: capitalize;
}

.arch__p_ {
    width: 210px;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 400;
    margin-left: 15px;
}

.arch__p_content {
    width: 210px;
    text-transform: capitalize;
    margin-bottom: 20px;
}


.arch__p_t {
    color: gray;
}


.arch__lps_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.arch__lps_cont_t {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}