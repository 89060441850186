.img404 {
    width: 500px;
}

.notFoundBTN {
    cursor: pointer;
}

.notFoundSpan {
    color: rgb(56, 179, 255);
}