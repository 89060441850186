.cdl__h2 {
    font-weight: 100;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 65px;
}

.cdl__cont {
    display: flex;
    margin-left: 15px;
    margin-bottom: 20px;
    height: 50px;
}

.cdl__name {
    text-transform: capitalize;
    color: rgb(62, 62, 62);
    width: 90px;
}

.cdl__limit {
    margin-left: 70px;
    text-align: center;
}

.cdl__rem {
    margin-left: 90px;
    width: 70px;
}

.cdl__status {
    margin-left: 100px;
    width: 70px;
}

.cdl__green {
    color: rgb(116, 182, 18);
}

.cdl__yellow {
    color: rgb(217, 185, 27);
}

.cdl__red {
    color: rgb(237, 60, 60);
}

.cdl__update_title {
    margin-left: auto;
    width: 350px;
}

.cdl__update_cont {
    margin-left: auto;
    width: 450px;
}

.cdl__btn {
    border: none;
    margin-left: 15px;
    cursor: pointer;
    background-color: white;
}

.cdl__btn:hover {
    color: dodgerblue;
}

.cdl__btn0 {
    margin-left: 300px;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.cdl__btn0:hover {
    color: dodgerblue;
}


.cdl__h2_cont {
    display: flex;
}

.cdl__h2_cont h3 {
    margin-left: auto;
    margin-bottom: 65px;
    margin-top: 25px;
    font-weight: 100;
    cursor: pointer;
}

.cdl__h2_cont h3:hover {
    color: dodgerblue;
}